<template>
  <div class="grid gap-5 md:grid-cols-2 relative">
    <div class="p-0 md:p-8">
      <!-- Non mobile gallery -->
      <Galleria
        v-if="!isMobile"
        class="mb-6 md:mb-10"
        :images="images"
        :responsiveOptions="responsiveOptions"
      />
      <!-- Mobile gallery -->
      <Galleria
        v-if="images && images.length > 0 && isMobile"
        :value="images"
        :numVisible="5"
        :showThumbnails="false"
        :showIndicators="true"
      >
        <template #item="{ item }">
          <div
            class="bg-flame-white w-full max-h-96 min-h-96 object-cover rounded-md"
            :style="{
              backgroundImage: `url(${item})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }"
          ></div>
        </template>
      </Galleria>
      <!-- Open quote content -->
      <div class="flex-col items-center w-full flex md:hidden">
        <h2 class="mb-2" v-if="item">{{ item[`${type}_name`] }}</h2>
        <span class="flex flex-col items-center justify-center mb-4">
          <span>Starting at:</span>
          <h3 class="ml-2 !font-medium" v-if="item">
            {{ $f.currency(item.item_type_price_net) }}
          </h3>
        </span>
      </div>
      <h2 class="mb-4 hidden md:block">Description</h2>
      <div class="mt-4" v-if="item && item.item_type_desc" v-html="item.item_type_desc"></div>
      <div v-else class="text-cool-gray-600">No Description</div>
    </div>
    <div class="p-0 md:p-8">
      <div class="flex items-center p-4 bg-cement-200 rounded-md mb-4">
        <div class="mr-5">
          <img class="min-w-10" src="@/assets/envelope-dot.svg" />
        </div>
        <div>
          <p>
            {{
              l(
                "Please complete the form below, and we'll instantly generate a shoppable estimate. You can make upgrades, refine your selections, and submit a request for proposal."
              )
            }}
          </p>
        </div>
      </div>
      <LeadFormContent
        v-if="!submitting && !submitted"
        :aoFields="form ? form.aoFields : []"
        :aoFieldOptions="form ? form.aoFieldOptions : []"
        :formFields="defaultFormFields"
        :oForm="form ? form.oForm : {}"
        @submit="onSubmit"
      />
      <div class="flex flex-col justify-center items-center h-full" v-if="submitting">
        <LoadingIndicator />
        <p class="text-xl mt-4">{{ l('Saving form submission') }}</p>
      </div>
      <div
        class="flex flex-col justify-center items-center h-full px-12 text-center"
        v-if="submitted"
      >
        <font-awesome-icon icon="circle-check" class="check !h-28 !w-28 text-matcha-500 mb-12" />
        <h1 class="mb-4">{{ l('Submission Successful') }}</h1>
        <div class="text-lg">
          {{
            l(
              `Thank you for your submission! We've received your details and are already working on creating an estimate for your project. We will follow up with you shortly to discuss the estimate and the next steps`
            )
          }}.
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import useTranslation from '@/components/composables/Translation'
import useForm from '@/components/forms/Form'
import { useRouter } from 'vue-router'
import useBreakpoint from '@/components/composables/Breakpoint'
import Galleria from '@/components/ui/Gallery/Galleria.vue'
import LeadFormContent from '@/components/leads/LeadFormContent.vue'
import LoadingIndicator from '@/components/ui/LoadingIndicator.vue'

const { l } = useTranslation()
const { isMobile } = useBreakpoint()
const route = useRouter()
const store = useStore()
const { form, defaultFormFields, submitting, submitted, fetchLeadForm, getDefaultFormFields } =
  useForm()

const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  leadFormId: {
    type: String
  },
  preview: {
    type: Boolean,
    default: false
  }
})

const companyId = computed(
  () => route.currentRoute.value.params.companyId || store.state.session.company.company_id
)

const type = computed(() => props.item.type)

const idField = computed(() => `${type.value}_id`)

const id = computed(() => props.item[idField.value])

const images = computed(
  () =>
    props.item &&
    props.item.file_ids &&
    props.item.file_ids.map((fileId) => {
      return `${import.meta.env.VITE_BASE_FILE_URL}file/view/${fileId}`
    })
)

const responsiveOptions = ref([
  {
    breakpoint: '1300px',
    numVisible: 4
  },
  {
    breakpoint: '575px',
    numVisible: 1
  }
])

const onSubmit = async ({ defaultFields, customFields }) => {
  submitting.value = true
  const data = {
    lead: {
      ...defaultFields,
      customFields,
      lead_rotation_id: form?.value?.lead_rotation_id
    },
    item: {
      [idField.value]: id.value,
      type: type.value
    },
    company: {
      companyId: companyId.value
    }
  }
  try {
    const response = await store.dispatch('Quote/submitOpenQuote', data)
    const { link, received } = response
    if (link) {
      store.dispatch('openLink', {
        url: link,
        newWindow: false
      })
    }
    if (received.success_url && !received.success_url.endsWith('/pub/leadReceived')) {
      store.dispatch('openLink', {
        url: received.success_url,
        newWindow: false
      })
    } else {
      submitted.value = true
    }
  } catch (e) {
    store.dispatch('alert', { error: true, message: e.message }, { root: true })
  }
  submitting.value = false
}

onMounted(() => {
  if (props.leadFormId) {
    fetchLeadForm(props.leadFormId)
  } else {
    getDefaultFormFields()
  }
})
</script>
