<template>
  <div class="openQuotes">
    <div class="grid gap-6 grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
      <!-- Add open quote card -->
      <div
        class="h-full w-full min-h-96 border-[3px] border-dashed border-surface-300 rounded-md flex items-center justify-center"
        :class="[!creating ? 'hover:border-pitch-black' : '']"
        v-if="edit"
      >
        <div v-if="!creating" class="flex items-center flex-col justify-center text-center p-3">
          <Btn class="mb-4" @click="onAddItem" size="xl" severity="primary">
            <font-awesome-icon icon="plus" />
            Publish assemblies
          </Btn>
          <p class="text-surface-400 font-medium leading-tight">
            Publish assemblies from your catalog to allow potential customers to estimate
            themselves.
          </p>
        </div>
        <span class="w-full h-full" v-else>
          <Skeleton width="100%" height="180px"></Skeleton>
          <Skeleton class="mt-6 mx-auto" width="88%" height="40px"></Skeleton>
          <Skeleton class="mt-2 mx-auto" width="88%" height="20px"></Skeleton>
          <Skeleton class="mt-7 mx-auto" width="88%" height="60px"></Skeleton>
        </span>
      </div>
      <!-- Open quote card -->
      <OpenQuoteItemCard
        :item="item"
        @view="onView"
        :key="item.id"
        :edit="edit"
        @status-change="onReload"
        @edit="onReload"
        v-for="item in result.hits"
      />
    </div>
    <div
      class="border rounded-md w-full mt-4 p-4 text-center"
      v-if="result && result.hits && result.hits.length === 0 && !edit"
    >
      <p class="text-cool-gray-500">
        There are currently no public items available to view in this catalog
      </p>
    </div>

    <!-- Open quote modal -->
    <MiniModal
      ref="openQuoteItemModal"
      :pt="{
        header: '!bg-white',
        content: '!bg-white'
      }"
      :scrollable="true"
      :closeable="false"
      size="full"
      :full="true"
      includeGutter="true"
    >
      <template #header>
        <div class="flex flex-col w-full md:mx-10">
          <div
            class="flex flex-col cursor-pointer py-4 hover:text-cool-gray-800"
            @click="onCloseModal"
          >
            <span class="!text-base">
              <font-awesome-icon class="mr-3" :icon="['far', 'arrow-left']" /> Back
            </span>
          </div>
          <div class="justify-between w-full hidden md:flex">
            <h2 class="mb-4" v-if="selectedItem">
              {{ selectedItem[`${selectedItem.type}_name`] }}
            </h2>
            <span class="flex items-center mb-4">
              <span class="text-2xl">Starting at:</span>
              <h2 class="ml-2 !text-2xl" v-if="selectedItem">
                <span>
                  {{ $f.currency(selectedItem.item_type_price_net) }}
                </span>
              </h2>
            </span>
          </div>
        </div>
      </template>
      <template #body>
        <OpenQuoteItem :item="selectedItem" :leadFormId="leadFormId" />
      </template>
    </MiniModal>

    <!-- Catalog modal -->
    <Modal size="lg" ref="openQuoteItemLibrary">
      <template #header>
        <div class="flex items-center justify-between w-full">
          <IconField iconPosition="left" class="flex">
            <InputIcon class="flex">
              <font-awesome-icon class="!text-base" :icon="['far', 'magnifying-glass']" />
            </InputIcon>
            <InputText
              class="!text-base"
              v-model="searchPhrase"
              placeholder="Search your catalog..."
              type="text"
            />
          </IconField>
          <Btn
            :disabled="selected.length === 0"
            @click="publishItems"
            size="lg"
            class="mr-4 ml-4"
            severity="primary"
          >
            Publish items <span v-if="selected.length > 0">({{ selected.length }})</span>
          </Btn>
        </div>
      </template>
      <template #body>
        <TraverseList
          ref="list"
          @select="selectHandler"
          :searchPhrase="searchPhrase"
          :selected="selected"
          :filters="{ assembly_is_public: 0, company_id: companyId }"
          :limit="40"
        >
          <template #no-results>
            <p class="text-3xl text-surface-200">No catalog items have been created</p>
            <to-route class="text-blue-print cursor-pointer" to="items"
              >You can go to your catalog to start creating items.</to-route
            >
          </template>
        </TraverseList>
      </template>
    </Modal>
  </div>
</template>

<script setup>
import { onMounted, defineProps, ref, defineEmits } from 'vue'
import { useStore } from 'vuex'
import Skeleton from 'primevue/skeleton'
import OpenQuoteItem from '@/components/openQuote/OpenQuoteItem.vue'
import OpenQuoteItemCard from '@/components/openQuote/OpenQuoteItemCard.vue'
import TraverseList from '@/components/ui/Traverse/TraverseList.vue'

import InputIcon from 'primevue/inputicon'
import IconField from 'primevue/iconfield'
import InputText from 'primevue/inputtext'

const emit = defineEmits(['result'])

const props = defineProps({
  companyId: {
    type: [String, Number],
    required: true
  },
  leadFormId: {
    type: String
  },
  edit: {
    type: Boolean,
    default: false
  }
})
const store = useStore()
const result = ref({
  hits: []
})
const selected = ref([])
const creating = ref(false)
const selectedItem = ref()
const openQuoteItemLibrary = ref()
const searchPhrase = ref('')
const openQuoteItemModal = ref()

const searchPublicItems = async () => {
  const { payload } = await store.dispatch('ajax', {
    path: `/api/${import.meta.env.VITE_LEAD_FORM_KEY}/external/catalog`,
    data: { company_id: props.companyId }
  })
  const hits = payload.hits.map((i) => i._source)
  result.value = {
    ...payload,
    hits
  }
  emit('result', result.value)
}

const onView = (item) => {
  selectedItem.value = item
  openQuoteItemModal.value.open()
}

const onCloseModal = () => {
  openQuoteItemModal.value.close()
}

const onReload = async () => {
  creating.value = true
  await searchPublicItems()
  creating.value = false
}

const onAddItem = () => {
  selected.value = []
  openQuoteItemLibrary.value.open()
}

const snakeToCamel = (str) => {
  if (!str.includes('_')) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
  }
  return str.toLowerCase().replace(/(_\w)/g, (match) => match[1].toUpperCase())
}

const selectHandler = (object) => {
  const newSelected = _.imm(selected.value)
  const found = newSelected.find(
    (s) =>
      (object.type === 'cost_type' && String(s.cost_type_id) === String(object.cost_type_id)) ||
      (object.type === 'assembly' && String(s.assembly_id) === String(object.assembly_id)) ||
      (object.type === 'autocost' && String(s.autocost_id) === String(object.autocost_id))
  )
  if (found) {
    newSelected.splice(newSelected.indexOf(found), 1)
    selected.value = newSelected
    return
  }

  selected.value = [...selected.value, object]
}

const publishItems = async () => {
  await Promise.all(
    selected.value.map((item) => {
      const { type, id } = item
      const itemType = snakeToCamel(type)
      return store.dispatch(`${itemType}/publish`, {
        id,
        isPublic: 1
      })
    })
  )
  selected.value = []
  openQuoteItemLibrary.value.close()
  searchPublicItems()
}

onMounted(() => {
  searchPublicItems()
})
</script>
