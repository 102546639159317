<template>
  <Card>
    <template #header>
      <div
        class="rounded-t-md w-full h-[11.5rem]"
        v-if="showCaseFileIds && showCaseFileIds.length > 0"
        :style="{
          backgroundImage: `url(${showCasePicture})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }"
      ></div>
      <div v-else class="flex items-center justify-center bg-cool-gray-100 w-full h-[11.5rem]">
        <font-awesome-icon class="text-3xl" :icon="['far', 'image']" />
      </div>
    </template>
    <template #title>
      <div class="flex flex-col">
        <span class="text-pitch-black font-normal">
          {{ $f.truncate(item[`${type}_name`], 45) }}
        </span>
        <span>
          {{ $f.currency(item.item_type_price_net) }}
        </span>
      </div>
    </template>
    <template #content>
      <div class="flex w-full pt-3 min-h-[5.5rem]">
        <span v-if="item.item_type_desc">
          {{ $f.truncate(stripHtml(item.item_type_desc), 75) }}
        </span>
      </div>
    </template>
    <template #footer>
      <div class="flex gap-2 mt-1">
        <Btn @click="emit('view', item)" severity="tertiary" size="lg" class="w-full">
          <font-awesome-icon :icon="['far', 'eye']" class="pr-2" /> {{ l('View') }}
        </Btn>
        <btn-bar
          size="md"
          btnClass="!border-none !text-xl !text-surface-500"
          :actions="actions"
          :collapse="true"
        />
      </div>
    </template>
  </Card>
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue'
import { useStore } from 'vuex'
import Card from 'primevue/card'
import useTranslation from '@/components/composables/Translation'

const emit = defineEmits(['view', 'statusChange', 'edit'])

const store = useStore()

const { l } = useTranslation()

const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  edit: {
    type: Boolean,
    default: false
  }
})

const showCaseFileIds = computed(() => props.item.file_ids)

const type = computed(() => props.item.type)

const id = computed(() => props.item[`${type.value}_id`])

const actions = computed(() => [
  ...(props.edit
    ? [
        {
          action: () => handlePublish(0),
          title: 'Unpublish',
          icon: ['fas', 'copy']
        },
        {
          action: handleEdit,
          title: 'Edit',
          icon: 'pencil'
        }
      ]
    : [])
])

const stripHtml = (html) => {
  // Create a temporary div element
  const tempDiv = document.createElement('div')

  // Set the innerHTML of the div to the provided HTML string
  tempDiv.innerHTML = html

  // Use textContent to get the plain text without HTML tags
  const text = tempDiv.textContent || tempDiv.innerText || ''

  // Replace multiple spaces with a single space
  return text.replace(/\s+/g, ' ').trim()
}

const snakeToCamel = (str) => {
  if (!str.includes('_')) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
  }
  return str.toLowerCase().replace(/(_\w)/g, (match) => match[1].toUpperCase())
}

const handleEdit = async () => {
  const storeName = snakeToCamel(type.value)
  try {
    await store.dispatch(`${storeName}/edit`, {
      id: id.value,
      type: type.value
    })
    emit('edit', { item: props.item })
  } catch (e) {
    store.dispatch('alert', { error: true, message: 'Could not update status' }, { root: true })
  }
}

const handlePublish = async (status) => {
  const { type } = props.item
  const itemType = snakeToCamel(type)
  try {
    await store.dispatch(`${itemType}/publish`, {
      id: id.value,
      isPublic: status
    })
    emit('statusChange', { status })
  } catch (e) {
    store.dispatch('alert', { error: true, message: 'Could not update status' }, { root: true })
  }
}

const showCasePicture = computed(() => {
  if (showCaseFileIds.value && showCaseFileIds.value[0]) {
    return `${import.meta.env.VITE_BASE_FILE_URL}file/view/${showCaseFileIds.value[0]}`
  }
  return ''
})
</script>
